<template>
  <div class="livestock-on-hand">
    <x-table
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @add="add"
      @search="search"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
      :showSummary="true"
      :summaryMethod="handleSummary"
    ></x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      @on-visible-change="modalShow"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="100">
        <FormItem label="养殖品种" prop="categoryId">
          <Select v-model="form.categoryId">
            <Option v-for="item in category" :value="item.id" :key="item.id">{{
              item.name
            }}</Option>
          </Select>
        </FormItem>
        <FormItem label="所属养殖区" prop="area">
          <Cascader :data="farmScene" v-model="form.area"></Cascader>
        </FormItem>
        <FormItem label="变更类型" prop="type">
          <Select v-model="form.type">
            <Option value="1">增</Option>
            <Option value="2">减</Option>
          </Select>
        </FormItem>
        <FormItem label="数量" prop="num">
          <Input v-model="form.num"></Input>
        </FormItem>
        <FormItem label="描述" prop="describes">
          <Input v-model="form.describes" type="textarea" :rows="3"></Input>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import CU from "@/common/util";
export default {
  name: "",
  data() {
    return {
      table: {
        columns: [
          {
            title: "所属基地",
            width: 300,
            key: "",
            render: (h, { row }) => {
              return <span>{row.farmName}</span>;
            },
          },
          {
            title: "所属养殖区",
            width: 300,
            render: (h, { row }) => {
              return <span>{row.sceneName}</span>;
            },
          },
          {
            title: "养殖品种",
            width: 200,
            key: "categoryName",
          },
          {
            title: "存栏量",
            minWidth: 200,
            key: "num",
          },
          {
            title: "操作",
            width: 150,
            render: (h, { row }) => {
              // {
              //   this.CA("loh_edit") && (
              //     <a style="margin-right:10px" onClick={() => this.edit(row)}>
              //       编辑
              //     </a>
              //   );
              // }
              // {
              //   this.CA("loh_del") && (
              //     <Poptip
              //       confirm
              //       transfer
              //       title="确定删除吗?"
              //       on-on-ok={() => this.del(row.id)}
              //     >
              //       <a>删除</a>
              //     </Poptip>
              //   );
              // }
              return (
                <div>
                  <a onClick={() => this.lohRecord(row)}>上报记录</a>
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        farmId: "",
        sceneId: "",
        categoryId: "",
        num: "",
        siteType: "2",
        type: "",
        describes: "",
        area: [],
      },
      rules: {
        categoryId: [{ required: true, message: "请选择养殖品种" }],
        area: [{ required: true, type: "array", message: "请选择养殖区" }],
        num: [
          { required: true, message: "请填写存栏量" },
          {
            validator(rule, value, callback) {
              if (!CU.validateNumber(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "请填写正确的存栏量",
          },
        ],
        type: [{ required: true, message: "请选择变更类型" }],
      },
      search_data: {},
      farmScene: [],
      category: [],
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "存栏上报",
          },
          width: 220,
          filterBox: [
            {
              conditionName: "养殖区",
              component: "cascader",
              field: "area",
              data: this.farmScene,
              defaultValue: [],
            },
            // {
            //   conditionName: "养殖品种",
            //   component: "select",
            //   field: "categoryId",
            //   data: this.category,
            //   defaultValue: "",
            //   parameterField: "id",
            //   showField: "name",
            // },
          ],
        },
        page: this.page,
      };
      return config;
    },
  },
  methods: {
    lohRecord(row) {
      this.$router.push({
        name: "inventoryRecord",
        query: { row: JSON.stringify(row) },
      });
    },
    add() {
      this.modal = {
        show: true,
        title: "存栏上报",
        submitLoading: false,
      };
    },

    search(data) {
      this.page.pageNo = 1;
      let n = {
        ...data,
        farmId: data.area[0],
        sceneId: data.area[1],
      };
      delete n.area;
      this.search_data = n;
      this.getList();
    },

    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },

    getList() {
      this.table.loading = true;
      this.$post(this.$api.LIVESTOCK_ON_HAND.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        siteType: "2",
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    getFarmScene() {
      this.$post(this.$api.PRODUCTION_AREA.FARMSCENE, {
        siteType: "2",
      }).then((res) => {
        this.farmScene = CU.formatTreeData(res, "id", "name", "sceneList");
      });
    },
    getCategory() {
      this.$post(this.$api.USER_PRODUCT.LIST,{
        type: 2
      }).then((res) => {
        this.category = res.list;
      });
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.categoryId = row.categoryId + "";
      this.form.area = [row.farmId, row.sceneId];
      this.modal = {
        show: true,
        title: "编辑存栏上报",
        submitLoading: false,
      };
    },
    del(id) {
      this.$post(this.$api.LIVESTOCK_ON_HAND.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },
    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        farmId: "",
        sceneId: "",
        categoryId: "",
        num: "",
        siteType: "2",
        type: "",
        describes: "",
        area: [],
      };
      this.$refs.form.resetFields();
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        params.farmId = this.form.area[0];
        params.sceneId = this.form.area[1];
        delete params.area;
        this.modal.submitLoading = true;
        this.$post(
          params.id
            ? this.$api.LIVESTOCK_ON_HAND.EDIT
            : this.$api.LIVESTOCK_ON_HAND.ADD_RECORD,
          params
        )
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
    handleSummary({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: "统计",
          };
          return;
        }
        const values = data.map((item) => Number(item[key]));
        if (column.key == "num") {
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[key] = {
              key,
              value: v,
            };
          } else {
            sums[key] = {
              key,
              value: "",
            };
          }
        } else {
          sums[key] = {
            key,
            value: "",
          };
        }
      });
      return sums;
    },
  },
  mounted() {
    this.getList();
    this.getFarmScene();
    this.getCategory();
  },
};
</script>

<style lang="less" scoped>
.livestock-on-hand {
  width: 100%;
  height: 100%;
}
</style>
